
/* line 7 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/main.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/node_modules/grunt-contrib-stylus/node_modules/stylus/lib/functions/index.styl */

/* line 286 : /Users/chemix/Sites/Projects/rancslunicko.cz/node_modules/grunt-contrib-stylus/node_modules/stylus/lib/functions/index.styl */

/* line 15 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/index.styl */

/* line 15 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/index.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/border.styl */

/* line 10 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/border.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/border-radius.styl */

/* line 53 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/border-radius.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/clearfix.styl */

/* line 28 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/clearfix.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/color-image.styl */

/* line 4 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/color-image.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/flex.styl */

/* line 196 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/flex.styl */

/* line 90 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/gradients.styl */

/* line 90 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/gradients.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/config.styl */

/* line 11 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/config.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/iconic.styl */

/* line 7 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/iconic.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/image.styl */

/* line 25 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/image.styl */

/* line 19 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/index.styl */

/* line 19 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/index.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/base.styl */

/* line 8 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/base.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/html5.styl */

/* line 19 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/html5.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/links.styl */

/* line 6 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/links.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/text.styl */

/* line 30 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/text.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/embed.styl */

/* line 7 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/embed.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/groups.styl */

/* line 17 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/groups.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/forms.styl */

/* line 69 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/forms.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/tables.styl */

/* line 7 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/normalize/tables.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/overflow.styl */

/* line 20 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/overflow.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/positions.styl */

/* line 66 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/positions.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/reset.styl */

/* line 78 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/reset.styl */

/* line 5 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/index.styl */

/* line 5 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/index.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/aliases.styl */

/* line 12 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/aliases.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/ellipsis.styl */

/* line 9 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/ellipsis.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/hide-text.styl */

/* line 8 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/hide-text.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/replace-text.styl */

/* line 9 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/replace-text.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/shadow-stroke.styl */

/* line 2 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/text/shadow-stroke.styl */

/* line 487 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/vendor.styl */

/* line 11 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/vendor.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/config.styl */

/* line 523 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/config.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/size.styl */

/* line 28 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/nib/nib/size.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/variables.styl */

/* line 27 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/variables.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/responsive.styl */

/* line 18 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/responsive.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */

/* line 5 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
body {
  font-family: 'Arial', sans-serif;
  color: #3e454c;
}

/* line 10 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
h1 {
  margin-bottom: 10px;
  font-size: 2em;
  margin-top: 0;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {

/* line 16 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
  h1 {
    margin-top: 0.5em;
  }
}

/* line 20 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
h2 {
  font-size: 1.6em;
}
@media only screen and (min-width: 768px) {

/* line 23 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
  h2 {
    margin-top: 0.3em;
  }
}

/* line 26 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
a {
  color: #2185c5;
  text-decoration: underline;
}

/* line 29 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
a:hover {
  color: #7ecefd;
}

/* line 32 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
.strike {
  text-decoration: line-through;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}

/* line 36 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
small,
.small {
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
  font-size: 80%;
}

/* line 42 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
ul {
  margin-left: 20px;
}

/* line 45 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */
p {
  font-size: 0.9rem;
}

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/typography.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */

/* line 5 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
body {
  background: #fff;
}

/* line 8 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.no {
  display: none !important;
}

/* line 11 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */

/* line 12 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-logo h1 {
  margin: 0;
  font-size: 1em;
}

/* line 16 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.top-line {
  height: 10px;
  background: #46bde3;
}

/* line 20 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.bottom-line {
  height: 10px;
  background: #5cda4a;
}

/* line 24 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-header {
  padding-top: 25px;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

/* line 29 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-header .button {
  font-size: 0.9em;
  background: #fff;
  color: #000;
  padding: 1em;
}

/* line 35 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-header .button:hover {
  background: #d4d4d4;
}

/* line 38 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-footer {
  margin-top: 4em;
  background: #372528;
  color: #976971;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* line 45 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-footer a {
  color: #976971;
}

/* line 48 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-footer a:hover {
  color: #b8818d;
}

/* line 51 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-footer__navigation {
  font-size: 0.8em;
}

/* line 54 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-highlight--light {
  background: #ccc;
}

/* line 57 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-highlight--silver {
  background: #afafaf;
}

/* line 60 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-highlight--dark {
  background: #999;
}

/* line 65 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.clear {
  clear: both;
}

/* line 69 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
div.flash {
  padding: 1em;
  margin: 1em 0;
  z-index: 10;
  -webkit-animation: fadeOut 10s;
  -moz-animation: fadeOut 10s;
  -o-animation: fadeOut 10s;
  -ms-animation: fadeOut 10s;
  animation: fadeOut 10s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  text-align: center;
}

/* line 99 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.flash.info {
  background: #58ca70;
  color: #fff;
}

/* line 103 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.flash.error {
  background: #7f0107;
  color: #fff;
}

/* line 107 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.box--high {
  background: #e8e8e8;
  padding: 1.5em 3em;
  margin: 1em 0;
}

/* line 113 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
span.tab {
  display: inline-block;
  width: 30px;
}

/* line 119 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.map {
  background: url("img/mapa.jpg") center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  height: 15em;
}

/* line 126 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.kontakt {
  padding-top: 2em;
  padding-bottom: 1em;
}

/* line 131 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.p-mailinglist {
  background: #e0e0e0;
  padding: 2em;
}

/* line 135 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.button--mailing {
  padding-right: 3em;
  padding-left: 3em;
}

/* line 140 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
input[type="email"].signup-input {
  padding: 1.78em;
  display: block;
}

/* line 145 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5em 3em;
  height: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOUk5Z7CgAB9AE+kvUQjQAAAABJRU5ErkJggg==");
  z-index: 100;
}

/* line 156 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation--open {
  display: block;
}

/* line 159 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation__item {
  display: inline-block;
  font-size: 2em;
  color: #eee;
  text-decoration: none;
  padding: 0.5em;
}

/* line 166 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation__item:hover {
  color: #fff;
  text-decoration: underline;
}

/* line 171 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation__close {
  display: block;
  height: 2em;
  width: 2em;
  top: 0;
  right: 0;
  position: absolute;
  font-size: 2em;
  color: #747474;
  text-decoration: none;
  padding: 0.5em;
}

/* line 183 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.b-navigation__close:hover {
  color: #000;
  text-decoration: none;
}

/* line 189 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.img-circle {
  border-radius: 50%;
}

/* line 193 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
.sl-wrapper button {
  color: #c0c0c0;
}

/* line 82 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
@-moz-keyframes fadeOut {

/* line 83 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 87 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 91 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  100% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    display: none !important;
  }
}

/* line 82 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
@-webkit-keyframes fadeOut {

/* line 83 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 87 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 91 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  100% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    display: none !important;
  }
}

/* line 82 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
@-o-keyframes fadeOut {

/* line 83 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 87 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 91 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  100% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    display: none !important;
  }
}

/* line 82 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
@keyframes fadeOut {

/* line 83 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 87 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

/* line 91 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */
  100% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    display: none !important;
  }
}

/* line 194 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/layout.styl */

/* line 194 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */

/* line 1 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card {
  border: none;
  min-height: 16em;
  padding: 10px;
  position: relative;
  margin: 2px;
  display: block;
  background: #c0c0c0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  overflow: hidden;
}

/* line 12 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 16em;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* line 25 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card:hover::after {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}

/* line 28 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card__title {
  z-index: 3;
  font-size: 2em;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 3em;
  display: block;
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

/* line 43 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card:hover .b-card__title {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

/* line 46 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card .zavorky {
  font-weight: normal;
  font-size: 1.3em;
}

/* line 50 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card__description {
  z-index: 2;
  color: #fff;
  position: absolute;
  top: 5em;
  text-align: center;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  padding: 1em;
  font-size: 1rem;
}

/* line 61 : /Users/chemix/Sites/Projects/rancslunicko.cz/www/css/site/card.styl */
.b-card:hover .b-card__description {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
